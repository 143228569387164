import { useEffect } from 'react';
import {
  loadFFmpeg, clearDirectory,
  listDirectory,
  createDirectory,
  writeFile,
  writeFiles,
  deleteFiles,
  fileExists,
  mixFiles,
} from './helpers';

export const useFFmpeg = () => {
  // initially load FFmpeg
  useEffect(() => {
    loadFFmpeg();
  }, []);

  return {
    ffmpeg: window.ffmpeg,
    clearDirectory,
    listDirectory,
    createDirectory,
    writeFile,
    writeFiles,
    deleteFiles,
    fileExists,
    mixFiles,
  };
};

'use client';

import { HubConnection, HubConnectionState } from '@microsoft/signalr';
import { useEffect } from 'react';
import { SignalREvent, SignalRMixingCompletedPayload, SignalRMixingRequestedPayload } from './types';
import { MIMEType } from '@/packages/ffmpeg/types';
import api from '@/packages/api/api';
import { useSignalR } from './SignalRContext';
import { Logger } from '@/packages/utils/log';
import { TRACK_EVENTS, applicationInsights } from '../azure/ApplicationInsights';
import { useFFmpeg } from '../ffmpeg/hooks';
import { useCampaignStore } from '@/app/(pages)/(home)/campaign/(pages)/[campaign]/store';

export const MixingSignalR = () => {
  const connection = useSignalR();
  const ffmpeg = useFFmpeg();
  const { updateSpot } = useCampaignStore();

  useEffect(() => {
    const onMixingRequested = async (payload: SignalRMixingRequestedPayload) => {
      const connection = window.connectionSignalR as HubConnection;

      // delete the audio files
      await ffmpeg.clearDirectory();

      // write the audio files
      await ffmpeg.writeFiles(payload.tracks);

      Logger('info', '🔌 SignalR', `Started mixing ${payload.name}`, payload);

      const audioTemplateResponse = await api.config.audioTemplate.get({ id: payload.audioTemplateId });
      if (!audioTemplateResponse.success) {
        return Logger('error', '🔌 SignalR', `Failed to get audio template`, audioTemplateResponse.message);
      }

      const audioTemplate = audioTemplateResponse.data;

      try {
        const startTime = performance?.now();
        const mix = await ffmpeg.mixFiles(payload.spotId, audioTemplate, payload.tracks);
        const blob = new Blob([mix.file], { type: MIMEType.wav });

        // POST the mixed audio
        const formData = new FormData();
        formData.append('spotId', String(payload.spotId));
        formData.append('file', blob);
        formData.append('Log', JSON.stringify(mix.logs));
        formData.append('IsSuccessful', 'true');
        formData.append('TaskHistoryId', payload.taskHistoryId);
        formData.append('ErrorMessage', '');

        const response = await api.spot.spotMixing(formData); // send the mixed audio to the server
        window.addEventListener('beforeunload', (event: BeforeUnloadEvent) => {
          // const message = 'Are you sure you want to leave this page?';
          // event.returnValue = message;
          // return message;
          if (connection) {
            connection.invoke(SignalREvent.ClientTaskFailed, 'User refreshed or closed the page').catch(error => {
              Logger('info', '🔌 SignalR', 'ClientTaskFailed error', { error });
              applicationInsights.trackException({ error }, { context: `ClientTaskFailed error`, payload });
            });
          }
        });

        applicationInsights.trackMetric({ name: 'SignalRAudioMixing', average: (performance?.now() || 0) - startTime });
        applicationInsights.trackEvent({
          name: TRACK_EVENTS.SpotMix,
          properties: {
            audioTemplate,
            averageTime: (performance?.now() || 0) - startTime,
          },
        });

        Logger('info', '🔌 SignalR', `Finished mixing ${payload.name}`, { response });
      } catch (error: any) {
        if (connection && connection.state === HubConnectionState.Connected) {
          connection.invoke(SignalREvent.ClientTaskFailed, JSON.stringify(error)).catch(error => {
            Logger('info', '🔌 SignalR', 'ClientTaskFailed error', { error });
            applicationInsights.trackException({ error }, { context: `ClientTaskFailed error`, payload });
          });
        }

        Logger('error', '🔌 SignalR', `Failed when mixing ${payload.name}`, error);
        applicationInsights.trackException({ error }, { context: `Failed when mixing`, payload });
      }
    };

    const onMixingCompleted = async (payload: SignalRMixingCompletedPayload) => {
      updateSpot(payload.spotId, { mergedAudioAssetId: payload.mergedAudioAssetId });
      Logger('info', '🔌 SignalR', `onMixingCompleted ${payload.spotId}`, payload);
    };

    if (connection && connection.state === HubConnectionState.Connected) {
      connection.on(SignalREvent.SpotMixingRequested, onMixingRequested);
      Logger('info', '🔌 SignalR', `Listening to SpotMixingRequested`);
      connection.on(SignalREvent.SpotMixingCompleted, onMixingCompleted);
      Logger('info', '🔌 SignalR', `Listening to SpotMixingCompleted`);
      connection.on(SignalREvent.SpotMixingCancelled, onMixingCompleted);
      Logger('info', '🔌 SignalR', `Listening to SpotMixingCancelled`);

      return () => {
        connection.off(SignalREvent.SpotMixingRequested, onMixingRequested);
        Logger('info', '🔌 SignalR', `Removed lister for SpotMixingRequested`);
        connection.off(SignalREvent.SpotMixingCompleted, onMixingCompleted);
        Logger('info', '🔌 SignalR', `Removed lister for SpotMixingCompleted`);
        connection.off(SignalREvent.SpotMixingCancelled, onMixingCompleted);
        Logger('info', '🔌 SignalR', `Removed lister for SpotMixingCancelled`);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connection, ffmpeg]);

  return null;
};

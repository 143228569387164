'use client';

import React from 'react';
import { twMerge } from 'tailwind-merge';
import { UserType } from '@/app/(pages)/account/types';
import { GetAllCampaignsItemsType } from '@/packages/api/resources/v1/campaign';
import { CampaignTableGroup } from './CampaignTableGroup';
import { useDictionary } from '@/packages/dictionary/DictionaryContext';

type Props = {
  className?: string;
  user: UserType;
  items: Array<GetAllCampaignsItemsType>;
};

export const CampaignTable: React.FC<Props> = ({ className, items, user }) => {
  const dictionary = useDictionary();

  const tableColumns = [
    {
      title: dictionary('campaigns.table.campaign.group'),
      size: '120px',
    },
    {
      title: dictionary('campaigns.table.campaign.name'),
      size: '0.31fr',
    },
    {
      title: dictionary('campaigns.table.campaign.status'),
      size: '180px',
    },
    {
      title: dictionary('campaigns.table.campaign.spots'),
      size: '65px',
    },
    {
      title: dictionary('campaigns.table.campaign.release'),
      size: '0.20fr',
    },
    {
      title: 'Created',
      size: '0.29fr',
    },
    {
      title: 'Last updated',
      size: '0.20fr',
    },
    {
      title: dictionary('campaigns.table.campaign.more'),
      size: '48px',
    },
  ];

  const gridTemplateColumns = tableColumns.map(col => col.size).join(' ');

  return (
    <div className="overflow-x-auto rounded-3xl">
      <div className={twMerge('min-w-[1100px] bg-white [&>*:not(:first-child)]:border-t [&>*:not(:first-child)]:border-blue-200/10', className)}>
        <div className="grid px-8 py-4" style={{ gridTemplateColumns: gridTemplateColumns }}>
          {tableColumns.map((col, i) => (
            <div className="flex text-nowrap text-left text-14 font-medium text-blue-200 last:justify-end" key={`table-col-${i}-${col}`}>
              {col.title}
            </div>
          ))}
        </div>

        {items.map(item => (
          <CampaignTableGroup
            key={JSON.stringify(item)}
            campaignGroup={item.campaignGroup}
            campaignGroupId={item.campaignGroupId}
            createdAt={item.createdAt}
            status={item.status}
            campaigns={item.campaigns}
            user={user}
            tableColumns={tableColumns}
          />
        ))}
      </div>
    </div>
  );
};
